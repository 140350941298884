import { Box, Typography, Button } from "@mui/material"
import { useNavigate } from "react-router-dom"

const Sobre = () => {
    const navigate = useNavigate()

    const GoRoute = (route: string) => {
        return (navigate(route))
    }
return (
    <Box 
        sx={{backgroundColor: '#DFD8D7'}}
        borderRadius={`20px`}
        display={'flex'} 
        flexDirection={'column'}
        width={'100%'} 
        padding={'20px'}
        fontFamily={'Poppins'}
        fontSize={'14px'}
        boxShadow={'2px 2px 4px #0000001a'}
        >
            <Typography variant="h6" sx={{fontFamily:'Poppins'}}>Sobre nós:</Typography>

            <Typography sx={{fontFamily:'Poppins', textAlign: 'justify'}}>
                Empresas Locais®, fundada em 2022, é uma plataforma vitríne de negócios locais, 
                com o intuíto de auxiliar a divulgar empresas locais, dando a elas espaço no mercado e presença online. 
                Ainda disponibiliza um guia de eventos públicos e privados da região além das vagas de empregos em aberto.
            </Typography>

            <Button 
                variant="contained" 
                color='secondary' 
                sx={{mt:2, borderRadius: 5}} 
                onClick={() => GoRoute('/contato')}
            > 
                Seja Membro
            </Button>

    </Box>
)}

export default Sobre