import { Box, Button, Grid, IconButton, Typography } from "@mui/material"
import { categorias, destaques } from "../../../../Storage"
import BoxPadrao from "../../../../Components/BoxPadrao/BoxPadrao"

const CategoriasMobile = () => {
    return(
        <BoxPadrao>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box padding={'8px 15px 2px 15px'} display={'flex'} justifyContent={'space-between'}>
                        <Typography variant="h5" fontFamily={'Poppins'} fontWeight={600}>
                            Categorias
                        </Typography>
                        <Button variant={'text'}>
                            Ver Mais
                        </Button>
                    </Box>
                </Grid>
                {categorias.map(categoria => {
                    return(
                        <Grid key={categoria.descricao} item xs={4} sm={3} md={3} lg={3}>
                            <Box 
                                display={'flex'} 
                                flexDirection={'column'} 
                                justifyContent={'center'} 
                                alignItems={'center'}
                                sx={{
                                    transition: '200ms',
                                    ':hover': {
                                        fontWeight: 600
                                    }
                                }}>
                                <Box 
                                    height={{xs:'50px', sm: '80px', md: '70px', lg: '70px', xl: '70px'}}
                                    width={{xs:'50px', sm: '80px', md: '70px', lg: '70px', xl: '70px'}}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}

                                    sx={{
                                        borderRadius: '300px',
                                        padding: '10px',
                                        boxShadow: '2px 2px 4px #00000098, -2px -2px 4px #fff',
                                        transition: '300ms',
                                        ":hover": {
                                            cursor: 'pointer',
                                            boxShadow: '4px 4px 8px #00000098, -4px -4px 8px #fff',
                                        }
                                }}>
                                    <IconButton sx={{width: '100%', height: '100%'}}>
                                        {<categoria.icon />}
                                    </IconButton>
                                </Box>
                                <Box fontFamily={'Poppins'} fontSize={'clamp(0.625rem, 0.4181rem + 1.0345vw, 1rem)'} textAlign={'center'} py={1}>
                                    {categoria.descricao}
                                </Box>
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
        </BoxPadrao>
    )
}

export default CategoriasMobile