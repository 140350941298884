import React from 'react'
import { Grid, TextField, Typography } from "@mui/material"
import InputPadrao from "../../../../Components/InputPadrao/InputPadrao"
import BotaoPadrao from '../../../../Components/BotaoPadrao/BotaoPadrao'

const LeadsForm = () => {

    const [ nome, setNome ] = React.useState('')
    const [ email, setEmail ] = React.useState('')
    const [ telefone, setTelefone ] = React.useState('')
    const [ descricao, setDescricao ] = React.useState('')
    
    return(
        <Grid container spacing={2} p={2}>
            <Grid item xs={12}>
                <Typography variant={'h5'} fontFamily={'Poppins'} textAlign={'center'} p={1}>
                    Torne-se membro agora!
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <InputPadrao
                    label='Nome'
                    type='text'
                    placeholder='Nome'
                    value={nome}
                    color='secondary'
                    size='small'
                    variant='outlined'
                    estilo={{width: '100%', borderRadius: '5px', backgroundColor: '#fefefe'}}
                    onChange={e => setNome(e.target.value)}
                />
            </Grid>
            
            <Grid item xs={12} md={8}>
                <InputPadrao
                    label='Email' 
                    type='text' 
                    placeholder='Email' 
                    value={email} 
                    color='secondary' 
                    size='small' 
                    variant='outlined'
                    estilo={{width: '100%', borderRadius: '5px', backgroundColor: '#fefefe'}}
                    onChange={e => setEmail(e.target.value)}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <InputPadrao
                    label='Telefone' 
                    type='tel' 
                    placeholder='(ddd) + Número' 
                    value={telefone}
                    color='secondary' 
                    size='small' 
                    variant='outlined'
                    estilo={{width: '100%', borderRadius: '5px', backgroundColor: '#fefefe'}}
                    onChange={e => setTelefone(e.target.value)}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    id="outlined-multiline-static"
                    label="Conte-nos sobre sua empresa"
                    type='text'
                    placeholder='Conte-nos sobre sua empresa'
                    size='medium'
                    sx={{width: '100%', borderRadius: '5px', backgroundColor: '#fefefe'}}
                    value={descricao}
                    color='secondary'
                    multiline
                    rows={4}
                    defaultValue="Default Value"
                    onChange={e => setDescricao(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} display={'flex'} justifyContent={'flex-end'} >
                <BotaoPadrao 
                    texto='Enviar'
                    color='secondary'
                    size='medium'
                    variant='contained'
                    onClick={() => alert('Foms enviado! Logo um consultor entrará em contato')}
                    estilo={{borderRadius: '100px', padding: '7px 40px 5px 40px'}}
                />
            </Grid>
            


            
        </Grid>
    )
}

export default LeadsForm;