import RestaurantIcon from '@mui/icons-material/Restaurant'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import HouseIcon from '@mui/icons-material/House';
import FactoryIcon from '@mui/icons-material/Factory';
import CarpenterIcon from '@mui/icons-material/Carpenter';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import ChairIcon from '@mui/icons-material/Chair';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import SpaIcon from '@mui/icons-material/Spa';
import LocalPharmacy from '@mui/icons-material/LocalPharmacy'
import { 
    Biotech, BreakfastDining, Build, ElectricBolt, Handyman, 
    Key, Pets, Plumbing, ShoppingCart, SportsBar 
} from '@mui/icons-material';

export const categorias = [
    {
        descricao: 'Bebidas',
        icon: SportsBar
    },
    {
        descricao: 'Beleza e Estética',
        icon: SpaIcon
    },
    {
        descricao: 'Carros e Motos',
        icon: DirectionsCarIcon
    },
    {
        descricao: 'Chaveiros',
        icon: Key
    },
    {
        descricao: 'Comunicação e Design',
        icon: DesignServicesIcon
    },
    {
        descricao: 'Consultórios',
        icon: LocalHospitalIcon
    },
    {
        descricao: 'Eletricistas',
        icon: ElectricBolt
    },
    {
        descricao: 'Encanadores',
        icon: Plumbing
    },
    {
        descricao: 'Escritórios',
        icon: BusinessCenterIcon
    },
    {
        descricao: 'Esportes e Lazer',
        icon: PedalBikeIcon
    },
    {
        descricao: 'Farmácias',
        icon: LocalPharmacy
    },
    {
        descricao: 'Imobiliárias',
        icon: HouseIcon
    },
    {
        descricao: 'Indústrias',
        icon: FactoryIcon
    },
    {
        descricao: 'Oficinas',
        icon: Build
    },
    {
        descricao: 'Laboratórios',
        icon: Biotech
    },
    {
        descricao: 'Maridos de Aluguel',
        icon: Handyman
    },
    {
        descricao: 'Materiais de Construção', 
        icon: CarpenterIcon
    },
    {
        descricao: 'Moda e Vestuário',
        icon: CheckroomIcon
    },
    {
        descricao: 'Móveis e Decoração',
        icon: ChairIcon
    },
    {
        descricao: 'Padarias',
        icon: BreakfastDining
    },
    {
        descricao: 'Petshops e Veterinárias',
        icon: Pets
    },

    {
        descricao: 'Restaurantes',
        icon: RestaurantIcon
    },
    {
        descricao: 'Supermercados',
        icon: ShoppingCart
    },
    {
        descricao: 'Viagens e Turismo',
        icon: AirplaneTicketIcon
    }
]