import * as React from 'react';
import TextField from '@mui/material/TextField';
import { UnionType } from 'typescript';

interface MeuInputProps {
    label: string,
    type: string,
    placeholder: string,
    value: string,
    color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    size: 'medium' | 'small',
    variant: 'filled' | 'outlined' | 'standard',
    estilo?: object, 
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
}

const InputPadrao = ({ label, type, value, placeholder, color, size, variant, onChange, estilo }: MeuInputProps) => {
    return (
        <TextField 
            variant={variant} 
            label={label}
            type={type} 
            placeholder={placeholder}
            color={color} 
            size={size}
            value={value} 
            sx={estilo}
            onChange={onChange} 
        />
    );
}

export default InputPadrao