import { Container, Grid } from "@mui/material"
import { Box } from "@mui/system"
import BoxPadrao from "../../../../Components/BoxPadrao/BoxPadrao"
import LeadsForm from "../LeadsForm/LeadsForm"
import LoginBox from "../LoginBox/LoginBox"

const MainContactPage = () => {
    return (
        <Container maxWidth={'xl'}>
            <Grid container spacing={2} pt={1}>
                <Grid item xs={12}>
                    <BoxPadrao>
                        <Box padding={'12px 20px 0 20px'}>
                            Empresas Locais®, fundada em 2022, é uma plataforma vitríne de negócios locais, 
                            com o intuíto de auxiliar a divulgar empresas locais, dando a elas espaço no mercado e presença online. 
                            Ainda disponibiliza um guia de eventos públicos e privados da região além das vagas de empregos em aberto.
                        </Box>
                    </BoxPadrao>
                </Grid>

                <Grid item xs={12} lg={4} display={'flex'}>
                    <BoxPadrao>
                        <LoginBox />
                    </BoxPadrao>
                </Grid>

                <Grid item xs={12} lg={8}>
                    <BoxPadrao>
                        <LeadsForm />
                    </BoxPadrao>
                </Grid>    
            </Grid>
        </Container>
    )
}

export default MainContactPage