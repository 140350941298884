import * as React from 'react';
import { AppBar, Box, Toolbar, Container } from '@mui/material';
import LogoHeader from './Logo/LogoHeader';
import MenuPC from './Menu/MenuPC';
import MenuMobile from './Menu/MenuMobile';
import AppSearch from './SearchInput/SearchInput';

function AppHeader() {

  return (
    <>
        <AppBar position="static" sx={{ padding: 2 }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>

                    {/* LOGO PC */}
                    <Box 
                        display={'flex'} 
                        flexDirection={'column'} 
                        alignItems={'start'}
                    >
                        <LogoHeader />
                        <Box 
                            sx={{
                                fontSize: '12px', 
                                display: { xs: 'none', md: 'flex' }
                            }}
                        >
                            Sapiranga/RS
                        </Box>
                    </Box>

                    {/* MENU MOBILE */}
                    <MenuMobile />

                    {/* LOGO MOBILE */}
                    <LogoHeader mobile />

                    {/* Pesquisa */} 
                    <AppSearch />
                    

                </Toolbar>
            </Container>
        </AppBar>
        <MenuPC />  
    </>
  );
}
export default AppHeader;