import React from 'react'
import { Typography, Grid } from "@mui/material"
import InputPadrao from "../../../../Components/InputPadrao/InputPadrao"
import BotaoPadrao from '../../../../Components/BotaoPadrao/BotaoPadrao'


const LoginBox = () => {
    const [ usuario, setUsuario ] = React.useState('');
    const [ senha, setSenha ] = React.useState('');

    return(
        <Grid container spacing={1} p={2} display={'block'}>
            <Grid item xs={12} display={'flex'} flexDirection={'column'} pb={2} pr={2} pl={2}>           
                <Typography variant={'h5'} fontFamily={'Poppins'} textAlign={'center'} p={1}>
                    Já é membro?
                </Typography>
                <Typography fontFamily={'Poppins'} textAlign={'center'}>
                    Preencha as informações abaixo para entrar em contato com o suporte
                </Typography>
            </Grid>

            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                <InputPadrao 
                    label='Usuário' 
                    type='text' 
                    placeholder='Usuário' 
                    value={usuario} 
                    color='secondary' 
                    size='small' 
                    variant='outlined'
                    estilo={{width: '90%', borderRadius: '5px', background: '#fefefe'}}
                    onChange={e => setUsuario(e.target.value)}
                />
            </Grid>
            
            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                <InputPadrao 
                    label='Senha' 
                    type='password' 
                    placeholder='Senha' 
                    value={senha} 
                    color='secondary' 
                    size='small'
                    variant='outlined'
                    estilo={{width: '90%', m: 1, borderRadius: '5px', background: '#fefefe'}}
                    onChange={e => setSenha(e.target.value)}
                />
            </Grid>

            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                <BotaoPadrao 
                    texto='Acessar'
                    color='secondary'
                    size='small'
                    variant='contained'
                    onClick={() => alert('vai pro Vatesape')}
                    estilo={{borderRadius: '100px', padding: '7px 40px 5px 40px'}}
                />
            </Grid>
        </Grid>
        
    )
}

export default LoginBox