import React from "react";
import { Box, IconButton, Menu, Container, InputBase } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

const AppSearch = () => {
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenSearchInputMobile = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseSearchInputMobile = () => {
        setAnchorElUser(null);
    };

    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    }));


    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: 'inherit',
        fontFamily: 'Poppins',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '15ch',
                '&:focus': {
                    width: '30ch',
                },
            },
        },
    }));

    const StyledInputSearchMobile = styled(InputBase)(({ theme }) => ({
        display: 'flex',
        fontFamily: 'Poppins',
        color: 'inherit',
        paddingLeft: `calc(1em + ${theme.spacing(0)})`,
        width: '100%',
    }));

    const SearchIconMobile = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'relative',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    return(
        <>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Pesquisar…"
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </Search>
            </Box>

            <Box sx={{ display: { xs: 'flex',  justifyContent: 'flex-end', md: 'none' }}}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenSearchInputMobile}
                    color="inherit"
                >
                    <SearchIcon />
                </IconButton>


                <Menu
                    sx={{ display: 'flex', flexDirection: 'row' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseSearchInputMobile}
                >
                    <Search sx={{ mt: '0px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <StyledInputSearchMobile
                            placeholder="Pesquisar…"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        <SearchIconMobile>
                            <SearchIcon />
                        </SearchIconMobile>
                    </Search>
                </Menu>
            </Box>
        </>
    )
}

export default AppSearch