import { Grid } from "@mui/material"
import AppFooter from "../../Components/Footer/AppFooter"
import AppHeader from "../../Components/Header/AppHeader"
import ExplorerMain from "./Components/ExplorerMain"

function ExplorerPage() {
    return(
        <Grid container display={'flex'} minHeight={'100vh'}>
            <Grid item xs={12} alignSelf={'flex-start'}>
                <AppHeader />
                <ExplorerMain />
            </Grid>

            <Grid item xs={12} alignSelf={'flex-end'}>
                <AppFooter/>
            </Grid>
        </Grid>
    )
}

export default ExplorerPage