import { Container, Grid } from '@mui/material'
import PropagandaNoConteudo from '../../IndexPage/Components/PropagandaNoConteudo/PropagandaNoConteudo'
import ExplorarPorCategoria from './ExplorarPorCategoria/ExplorarPorCategoria'
import LateralDestaquesDeHoje from './LateralDestaquesDeHoje/LateralDestaquesDeHoje'

const ExplorerMain = () => {
    return (
        <Container maxWidth={'xl'} sx={{marginTop: '10px', marginBottom: '30px'}}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <ExplorarPorCategoria />
                </Grid>
            </Grid>
        </Container>
    )
}

export default ExplorerMain