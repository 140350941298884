import { Box, Typography } from "@mui/material"
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';

interface LogoHeaderProps {
    mobile?: boolean
}

const LogoHeader = ({mobile}: LogoHeaderProps) => {
    return(
        <Box sx={{flexGrow: 1}} display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
            <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                    display: mobile? { xs: 'flex', md: 'none' }: { xs: 'none', md: 'flex' },
                    fontFamily: 'Poppins',
                    fontWeight: 500,
                    color: '#fefefe',
                    textDecoration: 'none',
                    lineHeight: '1.3rem',
                    fontSize: mobile? '1rem' : '1.7rem'
                }}
            >
                Empresas<br/>Locais
            </Typography>
            <PlaceOutlinedIcon 
                sx={{ 
                    fontSize: mobile? '2.2rem' : '3.5rem',  
                    display: mobile? { xs: 'flex', md: 'none' }: { xs: 'none', md: 'flex' },
                    mr: 1,
                    color: '#fefefe'
                }} 
                />
        </Box>   
    )
}

export default LogoHeader