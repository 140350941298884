import AppFooter from "../../Components/Footer/AppFooter";
import AppHeader from "../../Components/Header/AppHeader";
import AppMain from "./Components/Main";

const IndexPage = () => {
    return(
        <>
            <AppHeader />
            <AppMain />
            <AppFooter />
        </>
    )
};

export default IndexPage;
