import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { storageJobs } from '../../../../Storage'

function JobList() {
  return (
    <List sx={{ borderRadius: '1rem', width: '100%', bgcolor: 'background.paper', display: 'flex', flexDirection: 'column' }}>
      {storageJobs && storageJobs.map(job => {
        return (
          <>
            <ListItem alignItems="flex-start" key={job.uuid}>
              <ListItemText
                primary={job.cargo}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {job.remuneracao} 
                    </Typography>
                    {` — ${job.empresa}`}
                  </React.Fragment>
                }
              />
            </ListItem> 
            <Divider variant="fullWidth" component="li"/>
          </>
      )})}  
    </List>
  );
}


export default JobList