import { Box } from "@mui/material";
import { JsxElement } from "typescript";

interface BoxPadraoProps {
    children: React.ReactNode
}

function BoxPadrao(props: BoxPadraoProps) {
    return (
        <Box
            width={'100%'}
            sx={{backgroundColor: '#DFD8D7'}}
            borderRadius={`20px`}
            display={'flex'} 
            justifyContent={'center'}
            padding={'8px 10px 18px 10px'}
            boxShadow={'2px 2px 4px #0000001a'}
        >
            {props.children}
        </Box>
    )
}

export default BoxPadrao