import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { storageJobs } from "../../../../Storage";

function MenuLateralJobs() {
    const ItemMenuLateral = styled('div')(() => ({
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        borderTop: '1px solid #b3adac',
        padding: '3px 8px',
        transition: '200ms',
        '&:hover': {
            fontWeight: 600,
            cursor: 'pointer',
            background: '#b3adac5e'
        }
    }))

    const categoriasJobs = storageJobs.map(job => job.categoria)

    const catJob = categoriasJobs.filter((value, index, self) => {
        return self.indexOf(value) === index
    })

    return(
        <Box boxShadow={'2px 2px 4px #0000001a'} width='100%' borderRadius={'20px'}>
            <Box 
                borderRadius={`20px 20px 0 0 `}
                display={'flex'} 
                justifyContent={'center'}
                bgcolor="#b64f16"
                width={'100%'} 
                padding={'3px'}
            >
                <Typography
                    variant="h6"
                    color='#fcfcfc'
                >
                    Categorias
                </Typography>
            </Box>
            <Box
                sx={{backgroundColor: '#DFD8D7'}}
                id='menu-lateral'
                padding='8px 0px 15px 0px'
                borderRadius={'0 0 20px 20px'}>
                {catJob.map(job => {
                    return(
                        <ItemMenuLateral
                            key={job}
                        >
                            {job}
                        </ItemMenuLateral>
                    )
                })}
            </Box>
        </Box>
    )
}

export default MenuLateralJobs;