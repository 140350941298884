import { Box, styled, Typography } from '@mui/material'
import { categorias } from '../../../../Storage'

const MenuLateral = () => {

    const ItemMenuLateral = styled('div')(() => ({
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        borderTop: '1px solid #b3adac',
        padding: '3px 8px',
        transition: '200ms',
        '&:hover': {
            fontWeight: 600,
            cursor: 'pointer',
            background: '#b3adac5e'
        }
    }))

    return(
        <Box boxShadow={'2px 2px 4px #0000001a'} borderRadius={'20px'}>
            <Box 
                borderRadius={`20px 20px 0 0 `}
                display={'flex'} 
                justifyContent={'center'}
                bgcolor="#b64f16"
                width={'100%'} 
                padding={'3px'}
            >
                <Typography
                    variant="h6"
                    color='#fcfcfc'
                >
                    Explorar
                </Typography>
            </Box>
            <Box
                sx={{backgroundColor: '#DFD8D7'}}
                id='menu-lateral'
                padding='8px 0px 15px 0px'
                borderRadius={'0 0 20px 20px'}>
                {categorias.map(categoria => {
                    return(
                        <ItemMenuLateral
                            key={categoria.descricao}
                        >
                            {categoria.descricao}
                        </ItemMenuLateral>
                    )
                })}
            </Box>
        </Box>
    )
}

export default MenuLateral