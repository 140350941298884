import BoxPadrao from "../../../../Components/BoxPadrao/BoxPadrao";
import { Grid, Button, Box, Typography, IconButton } from '@mui/material'
import { categorias, destaques } from "../../../../Storage"

function ExplorarPorCategoria() {
    return(
        <BoxPadrao>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box padding={'20px 15px 0 15px'} display={'flex'} justifyContent={'space-between'}>
                        <Typography fontSize={'clamp(1.3rem, 0.7337rem + 1.5217vw, 1.7rem)'} fontFamily={'Poppins'} fontWeight={600}>
                            Explorar pelas Categorias
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        {categorias.map(categoria => {
                            return(
                                <Grid key={categoria.descricao} item xs={12} sm={6} lg={3} display={'flex'} justifyContent={'space-evenly'}>
                                    <Box 
                                        display={'flex'} 
                                        width={{xs:'98%'}}
                                        alignItems={'center'}
                                        sx={{
                                            m: '3px 0',
                                            borderRadius: '100px',
                                            transition: '200ms',
                                            ':hover': {
                                                fontWeight: 600,
                                                cursor: 'pointer',
                                                boxShadow: '4px 4px 8px #00000098, -4px -4px 8px #fff',
                                    }}}>
                                        <Box 
                                            display={'flex'}
                                            justifyContent={'center'}
                                            alignItems={'center'}

                                            sx={{
                                                borderRadius: '300px',
                                                padding: '3px',
                                                boxShadow: '2px 2px 4px #00000098, -2px -2px 4px #fff',
                                                transition: '300ms',
                                                margin: 'clamp(0.3125rem, 0.1223rem + 1.087vw, 0.5rem)',
                                                ":hover": {
                                                    boxShadow: '4px 4px 8px #00000098, -4px -4px 8px #fff',
                                        }}}>
                                            <IconButton>
                                                {<categoria.icon sx={{fontSize: '28px'}}/>}
                                            </IconButton>
                                        </Box>
                                        <Box 
                                            m={'0 8px'}
                                            fontFamily={'Poppins'} 
                                            fontSize={'clamp(1rem, 0.9239rem + 0.4348vw, 1.15rem)'} 
                                        >
                                            {categoria.descricao}
                                        </Box>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </BoxPadrao>
    )
}

export default ExplorarPorCategoria