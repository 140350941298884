export const pages = [
    {
        titulo: 'Início',
        rota: '/'
    },
    {
        titulo: 'Explorar',
        rota: '/explorar'
    },
    {
        titulo: 'Agendas',
        rota: '/agenda'
    },
    {
        titulo: 'Empregos',
        rota: '/empregos'
    },
    {
        titulo: 'Contato',
        rota: '/contato'
    },
];