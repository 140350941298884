import { Box, Button, Grid, Typography } from "@mui/material"
import { destaques } from "../../../../Storage"
import BoxPadrao from "../../../../Components/BoxPadrao/BoxPadrao"

const Destaques = () => {
    return(
        <BoxPadrao>
            <Grid container spacing={{xs: 1, sm: 2}}>
                <Grid item xs={12}>
                    <Box padding={'8px 15px 2px 15px'} display={'flex'} justifyContent={'space-between'}>
                        <Typography variant="h5" fontFamily={'Poppins'} fontWeight={600}>
                            Destaques
                        </Typography>
                        <Button variant={'text'}>
                            Ver Mais
                        </Button>
                    </Box>
                </Grid>
                {destaques.map(destaque => {
                    let tamanhoXS = 0
                    let tamanhoSM = 0
                    // if(destaque.posicao < 4) {
                    //     tamanhoXS = 4
                    //     tamanhoSM = 4
                    // } else if (destaque.posicao < 12) {
                    //     tamanhoXS = 3
                    //     tamanhoSM = 3
                    // } else {
                    //     tamanhoXS = 4
                    //     tamanhoSM = 4
                    // }
                    if(destaque.posicao < 3) {
                        tamanhoXS = 6
                        tamanhoSM = 6
                    } else if (destaque.posicao < 6) {
                        tamanhoXS = 4
                        tamanhoSM = 4
                    } else if (destaque.posicao < 8) {
                        tamanhoXS = 6
                        tamanhoSM = 6
                    } else if (destaque.posicao < 11) {
                        tamanhoXS = 4
                        tamanhoSM = 4
                    } else if (destaque.posicao < 13) {
                        tamanhoXS = 6
                        tamanhoSM = 6
                    } else {
                        tamanhoXS = 4
                        tamanhoSM = 4
                    }
                    return(
                        <Grid key={destaque.posicao} item xs={tamanhoXS} sm={tamanhoSM}>
                            <Box 
                                height={'clamp(4.375rem, 3.4239rem + 5.4348vw, 7.5rem)'
                                
                            }
                                sx={{
                                    backgroundImage: `url(${destaque.img})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    borderRadius: '20px',
                                    padding: '10px',
                                    boxShadow: '2px 2px 4px #00000098, -4px -4px 4px #fff',
                                    transition: '300ms',
                                    ":hover": {
                                        cursor: 'pointer',
                                        boxShadow: '4px 4px 4px #00000098, -8px -8px 8px #fff',
                                    }
                            }}>
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
        </BoxPadrao>
    )
}

export default Destaques