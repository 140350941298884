import React from "react"
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ContactPage from "../Pages/ContactPage/ContactPage"
import ExplorerPage from "../Pages/ExplorerPage/ExplorerPage"
import IndexPage from "../Pages/IndexPage/IndexPage"
import JobsPage from "../Pages/JobsPage/JobsPage"

const AppRoutes = () => {
    return(
        <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<IndexPage />} />
                <Route path={'/explorar'} element={<ExplorerPage />} />
                <Route path={'/contato'} element={<ContactPage />} />
                <Route path={'/empregos'} element={<JobsPage />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes