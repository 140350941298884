export const storageJobs = [
        {
          uuid: '1abf17f5-20d5-4ed5-8f5d-2d978932f5e5',
          cargo: 'Desenvolvedor Front-End',
          empresa: 'Empresa A',
          remuneracao: 'R$ 5.000,00',
          categoria: 'TI',
          contato: 'contato@empresaA.com',
          dataLimite: '2023-04-01',
          dataAbertura: '2023-03-01'
        },
        {
          uuid: '5b5ca3b8-9f52-4d7e-8dc3-3b6c4d0a4c0d',
          cargo: 'Analista de Marketing',
          empresa: 'Empresa B',
          remuneracao: 'R$ 4.000,00',
          categoria: 'Marketing',
          contato: 'contato@empresaB.com',
          dataLimite: '2023-04-10',
          dataAbertura: '2023-03-01'
        },
        {
          uuid: 'fc8edcb6-214a-4ab4-a1e8-02b9e657ebd6',
          cargo: 'Assistente Administrativo',
          empresa: 'Empresa C',
          remuneracao: 'R$ 2.500,00',
          categoria: 'Administração',
          contato: 'contato@empresaC.com',
          dataLimite: '2023-04-15',
          dataAbertura: '2023-03-01'
        },
        {
          uuid: '8e44d2e2-29b6-4f2d-8e47-3a3c9b9a7f4e',
          cargo: 'Engenheiro de Software',
          empresa: 'Empresa D',
          remuneracao: 'R$ 8.000,00',
          categoria: 'TI',
          contato: 'contato@empresaD.com',
          dataLimite: '2023-04-30',
          dataAbertura: '2023-03-01'
        },
        {
          uuid: 'a9bc08f1-8a05-41d6-81b2-f2e2a8a8c2f2',
          cargo: 'Analista de Recursos Humanos',
          empresa: 'Empresa E',
          remuneracao: 'R$ 3.500,00',
          categoria: 'Recursos Humanos',
          contato: 'contato@empresaE.com',
          dataLimite: '2023-04-05',
          dataAbertura: '2023-03-01'
        },
        {
          uuid: '84d1c5f5-3649-47e2-8625-d5f5b5d5e5ab',
          cargo: 'Analista de Sistemas',
          empresa: 'Empresa F',
          remuneracao: 'R$ 6.000,00',
          categoria: 'TI',
          contato: 'contato@empresaF.com',
          dataLimite: '2023-04-20',
          dataAbertura: '2023-03-01'
        },
        {
            uuid: '1abf17f5-20d5-4ed5-8f5d-2d978932f5e5',
            cargo: 'Desenvolvedor Front-End',
            empresa: 'Empresa A',
            remuneracao: 'R$ 5.000,00',
            categoria: 'TI',
            contato: 'contato@empresaA.com',
            dataLimite: '2023-04-01',
            dataAbertura: '2023-03-01'
          },
          {
            uuid: '5b5ca3b8-9f52-4d7e-8dc3-3b6c4d0a4c0d',
            cargo: 'Analista de Marketing',
            empresa: 'Empresa B',
            remuneracao: 'R$ 4.000,00',
            categoria: 'Marketing',
            contato: 'contato@empresaB.com',
            dataLimite: '2023-04-10',
            dataAbertura: '2023-03-01'
          },
          {
            uuid: 'fc8edcb6-214a-4ab4-a1e8-02b9e657ebd6',
            cargo: 'Assistente Administrativo',
            empresa: 'Empresa C',
            remuneracao: 'R$ 2.500,00',
            categoria: 'Administração',
            contato: 'contato@empresaC.com',
            dataLimite: '2023-04-15',
            dataAbertura: '2023-03-01'
          },
          {
            uuid: '8e44d2e2-29b6-4f2d-8e47-3a3c9b9a7f4e',
            cargo: 'Engenheiro de Software',
            empresa: 'Empresa D',
            remuneracao: 'R$ 8.000,00',
            categoria: 'TI',
            contato: 'contato@empresaD.com',
            dataLimite: '2023-04-30',
            dataAbertura: '2023-03-01'
          },
          {
            uuid: 'a9bc08f1-8a05-41d6-81b2-f2e2a8a8c2f2',
            cargo: 'Analista de Recursos Humanos',
            empresa: 'Empresa E',
            remuneracao: 'R$ 3.500,00',
            categoria: 'Recursos Humanos',
            contato: 'contato@empresaE.com',
            dataLimite: '2023-04-05',
            dataAbertura: '2023-03-01'
          },
          {
            uuid: '84d1c5f5-3649-47e2-8625-d5f5b5d5e5ab',
            cargo: 'Analista de Sistemas',
            empresa: 'Empresa F',
            remuneracao: 'R$ 6.000,00',
            categoria: 'TI',
            contato: 'contato@empresaF.com',
            dataLimite: '2023-04-20',
            dataAbertura: '2023-03-01'
          }
]
      