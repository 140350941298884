import React from 'react';
import { createTheme } from '@mui/material';
import { ptBR } from '@mui/material/locale';

export const Theme = createTheme(
    {
        palette: {
            primary: {
                main: '#b64f16'
                // main: '#080f0f'
            },

            secondary: {
                main: '#080f0f'
                // main: '#b64f16'
            },

            error: {
                main: '#bb0a21'
            },

            warning: {
                main: '#3e000c'
            },

            info: {
                main: '#fffcf12'
            },

            success: {
                main: '#008148'
            },
        },
    },
    ptBR
);