import styled from "@emotion/styled"
import { Box, Button } from "@mui/material"
import { Container } from "@mui/system"
import { useNavigate } from "react-router-dom"
import { pages } from "../../../Storage"


const BotaoMenu = styled('button')(() => ({
    backgroundColor: "#080f0f", 
    padding: '5px',
    boxShadow: '2px 2px 4px #00000067',
    width:'19%', 
    borderRadius: '25px',
    border: 0,
    display: 'block',
    color: '#e6e0e0',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    transition: '300ms',
    '&:hover': {
        cursor:'pointer',
        fontWeight: 500,
        boxShadow: '4px 4px 8px #000000d1',
    }
}))



const MenuPC = () =>{
    
    const navigate = useNavigate()

    const GoRoute = (route: string) => {
        return (navigate(route))
    }
    return(
            <Container maxWidth={'xl'} sx={{ padding: '10px 0'}}>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent:"space-between" }}>
                    {pages.map((page) => (
                    <BotaoMenu
                        onClick={() => GoRoute(page.rota)}
                        key={page.titulo}
                    >
                        {page.titulo}
                    </BotaoMenu>
                    ))}
                </Box>
            </Container>
    )
}

export default MenuPC