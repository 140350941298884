import { Box, Grid } from "@mui/material";
import { Container } from "@mui/system";
import BoxPadrao from "../../../Components/BoxPadrao/BoxPadrao";
import JobList from "./JobList/JobList";
import MenuLateralJobs from "./MenuLateral/MenuLateral";

function JobsMain() {
    return(
        <Container maxWidth={'xl'} sx={{marginTop: '10px', marginBottom: '30px'}}>
            <Grid container maxWidth={'xl'} spacing={2}>
                <Grid item xs={12} md={3}>
                    <Box>
                        <MenuLateralJobs />
                    </Box>
                </Grid>
                <Grid item xs={12} md={9}>
                    <BoxPadrao>
                        <JobList />
                    </BoxPadrao>
                </Grid>
            </Grid>
        </Container>
        
    )
}

export default JobsMain;