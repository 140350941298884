import { AppBar } from "@mui/material"
import LogoHeader from "../Header/Logo/LogoHeader"

const AppFooter = () => {
    return(
        <AppBar 
            position='static'
            sx={{
                backgroundColor: '#080f0f',
                padding: 2, 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                mt: 2
            }}>
            <LogoHeader />
            <span style={{fontSize: '12px'}}>Todos os direitos reservados. ©</span>


        </AppBar>
    )
}

export default AppFooter