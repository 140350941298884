
import { Box, Container, Grid, Typography, Menu, MenuItem} from "@mui/material"
import CategoriasMobile from "./CategoriasMobile/CategoriasMobile"
import Destaques from "./Destaques/Destaques"
import MenuLateral from "./MenuLateral/MenuLateral"
import Novidades from "./Novidades/Novidades"
import PropagandaNoConteudo from "./PropagandaNoConteudo/PropagandaNoConteudo"
import Sobre from "./SobreNos/SobreNos"

const AppMain = () => {
    return(
        <Container maxWidth={'xl'} sx={{marginTop: '10px'}}>
            <Grid container spacing={{xl:2, xs:2}}>

                {/* MENU LATERAL */}
                <Grid item display={{xs: 'none', md: 'block'}} xs={2} >
                    <MenuLateral />
                </Grid>

                {/* CONTEUDO */}
                <Grid item xs={12} md={8}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Destaques />
                        </Grid>
                        <Grid item xs={12}>
                            <PropagandaNoConteudo />
                        </Grid>
                        <Grid item xs={12}>
                            <Novidades />
                        </Grid>
                        <Grid item xs={12}>
                            <PropagandaNoConteudo />
                        </Grid>
                        <Grid item xs={12} display={{xs: 'flex', md: 'none'}}>
                            <CategoriasMobile />
                        </Grid>
                    </Grid>
                </Grid>

                {/* COLUNA DIREITA */}
                <Grid item xs={12} md={2}>
                    <Sobre/>
                </Grid>
            </Grid>
        </Container>
    )
}

export default AppMain