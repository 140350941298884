import { Box } from "@mui/material"

const PropagandaNoConteudo = () => {
    return(
        <Box
            width={'100%'}
            height={{md:'120px', xs:'90px'}}
            display={'flex'} 
            justifyContent={'center'}
        >
            <img src='./assets/img/Propaganda.png' alt='Propaganda' width={'100%'} />
        </Box>
    )
}

export default PropagandaNoConteudo